import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Зачем нужен веб-скрейпинг?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Веб-скрейпинг — это автоматизированный процесс получения большого
            объема данных из интернета. В маркетинговых и продуктовых целях его
            используют для изучения рынка и мониторинга цен конкурентов.
          </Trans>
        </p>
        <p>
          <Trans>
            Большинство популярных сайтов активно защищают свои ресурсы от
            скрейпинга используя распознавание IP-адреса, проверку User Agent
            браузера, языка системы и другие способы идентификации.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser имеет преимущество перед скриптами и
            программами-скрейперами. Виртуальный профиль воспринимаются
            интернет-ресурсами, как рядовой пользователь зашедший на сайт и
            получает всю информацию без ограничений.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Чем помогает антидетект браузер в веб-скрейпинге?</Trans>,
    answer: (
      <>
        <Trans>
          Ценная информация в интернете, наиболее защищена от скрейпинга. Речь
          идёт не только о проверке HTTP-заголовка или IP-адреса, который легко
          изменить с помощью прокси. Отслеживаются веб-шрифты, расширения,
          cookie-файлы и другие параметры цифровых отпечатков. В этом случае
          использование Octo Browser становится необходимым. Для безопасного
          сбора данных в Octo Browser используются браузерные отпечатки реальных
          устройств, не вызывающие никаких подозрений у защитных систем сайтов.
        </Trans>
      </>
    ),
  },
  {
    question: (
      <Trans>Как извлекать данные с сайтов, чтобы вас не заблокировали?</Trans>
    ),
    answer: (
      <Trans>
        Основная причина блокировок — плохо настроенная автоматизация. Не
        создавайте огромное количество запросов с одного IP-адреса, такие IP
        быстро попадают в чёрный список. Лучше использовать несколько
        динамических прокси и привести частоту запросов с каждого IP-адреса к
        безопасным значениям. В случае блокировки, игнорирующей смену прокси,
        Octo Browser позволяет полностью изменить отслеживаемые параметры
        цифрового отпечатка и продолжить сбор данных.
      </Trans>
    ),
  },
];

type FaqScrappingProps = {
  title: JSX.Element;
};
const FaqScrapping = ({ title }: FaqScrappingProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqScrapping;
