import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headSrc from '../../assets/images/solutions/scrapping/head.png';
import headSrc2x from '../../assets/images/solutions/scrapping/head_2x.png';
import Description1 from '../../assets/images/solutions/scrapping/description_1.svg';
import Description2 from '../../assets/images/solutions/scrapping/description_2.svg';
import Description3 from '../../assets/images/solutions/scrapping/description_3.svg';
import Description4 from '../../assets/images/solutions/scrapping/description_4.svg';
import block1Src from '../../assets/images/solutions/scrapping/block_1.png';
import block1Src2x from '../../assets/images/solutions/scrapping/block_1_2x.png';
import block2Src from '../../assets/images/solutions/scrapping/block_2.png';
import block2Src2x from '../../assets/images/solutions/scrapping/block_2_2x.png';
import block3Src from '../../assets/images/solutions/scrapping/block_3.png';
import block3Src2x from '../../assets/images/solutions/scrapping/block_3_2x.png';
import block4Src from '../../assets/images/solutions/scrapping/block_4.png';
import block4Src2x from '../../assets/images/solutions/scrapping/block_4_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqScrapping from '../../components/FaqScrapping/FaqScrapping';

const ScrappingPage = (): JSX.Element =>  {
  const head = {
    title: <Trans>Веб-скрейпинг</Trans>,
    text: (
      <Trans>
        Octo Browser — это универсальный инструмент для быстрого и безопасного веб-скрейпинга, позволяющий обходить защиту сайтов от сбора данных.
      </Trans>
    ),
    images: [
      {
        image: <ImageContainer src={headSrc} srcSet={{ _2x: headSrc2x }} />,
      },
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Браузерные отпечатки реальных устройств</Trans>,
      text: (
        <Trans>
          Используйте виртуальные профили с уникальными браузерными отпечатками для получения доступа к данным сайтов, не опасаясь блокировки.</Trans>
      ),
    },
    {
      icon: <Description2 />,
      title: <Trans>Автоматизация через API</Trans>,
      text: (
        <Trans>
          Избавьтесь от необходимости вручную управлять тысячами профилей. Большинство процессов доступны для автоматизации.
        </Trans>
      ),
    },
    {
      icon: <Description3 />,
      title: <Trans>Прокси</Trans>,
      text: (
        <Trans>
          Быстрый и удобный процесс массовой загрузки и сохранения всех популярных типов прокси: http, socks5, ssh.
        </Trans>
      ),
    },
    {
      icon: <Description4 />,
      title: <Trans>Качественный headless браузер</Trans>,
      text: (
        <Trans>
          Оптимизируйте процесс скрейпинга данных, обращаясь к виртуальными профилями напрямую, без необходимости запускать клиент Octo Browser.
        </Trans>
      ),
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer src={block1Src} srcSet={{ _2x: block1Src2x }} />
            ),
          },
        ],
        title: <Trans>Маскировка активности</Trans>,
        text: (
          <Trans>
            Сохраняйте конфиденциальность при мониторинге торговых площадок, сборе данных о ценах и ассортименте конкурентов, лишая их возможности отреагировать.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block2Src} srcSet={{ _2x: block2Src2x }} />
            ),
          },
        ],
        title: <Trans>Экономия ресурсов</Trans>,
        text: (
          <Trans>
            Снижайте затраты на использование физических и виртуальных серверов, собирая данные с помощью виртуальных профилей.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block3Src} srcSet={{ _2x: block3Src2x }} />
            ),
          },
        ],
        title: <Trans>Сокращение издержек и доработок</Trans>,
        text: (
          <Trans>
            Избавьтесь от ручного прохождения верификации или от необходимости усложнять скрейпер функцией обхода капчи и связанных этим издержек.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block4Src} srcSet={{ _2x: block4Src2x }} />
            ),
          },
        ],
        title: <Trans>Доступ к закрытой информации</Trans>,
        text: (
          <Trans>
            Быстрый и безопасный доступ к данным сайтов, требующих аутентификации, обход уже существующих блокировок в соцсетях и блогах.
          </Trans>
        ),
      },
    ],
    title: (
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>Быстрое и безопасное получение данных</Trans>}
        title={<Trans>с Octo Browser</Trans>}
        highlightPosition="start"
        maxWidthTitle={800}
      />
    ),
  };
  const faq = (
    <FaqScrapping
      title={
        <BlockTitle
          highlightIsBlock
          highlightText={<Trans>с антидетект браузером</Trans>}
          title={<Trans>Как веб-скрейпинг связан</Trans>}
          maxWidthTitle={770}
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default ScrappingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["scrappingPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
